import React from "react";
import "./Hero.css";
import Tabs from "../Tabs/Tabs";
import first from "../../Assets/first.jpeg";
import splassive from "../../Assets/splassive.PNG";
import drip from "../../Assets/drip.PNG";
import nft from "../../Assets/nft.PNG";

const Hero = () => {
  const tabs = [
    {
      label: "Arbi Tech",
      content: (
        <>
          <h3>
            <span>Chief Technology Officer / Senior Blockchain Developer</span>
            <span class="company">
              &nbsp;@&nbsp;
              <a
                href="https://www.linkedin.com/company/arbitech-solution/"
                class="inline-link"
                rel="noopener noreferrer"
                target="_blank"
              >
                Arbi Tech
              </a>
            </span>
          </h3>
          <p class="range">April 2020 - March 2022</p>
          {/* <div className="QgHxr">
            <ul>
              <li>
                Deliver high-quality, robust production code for a diverse array
                of projects for clients including Harvard Business School,
                Everytown for Gun Safety, Pratt Institute, Koala Health,
                Vanderbilt University, The 19th News, and more
              </li>
              <li>
                Work alongside creative directors to lead the research,
                development, and architecture of technical solutions to fulfill
                business requirements
              </li>
              <li>
                Collaborate with designers, project managers, and other
                engineers to transform creative concepts into production
                realities for clients and stakeholders
              </li>
              <li>
                Provide leadership within engineering department through close
                collaboration, knowledge shares, and mentorship
              </li>
            </ul>
          </div> */}
        </>
      ),
    },
    {
      label: "Spyresync",
      content: (
        <>
          <h3>
            <span>Senior Blockchain Developer</span>
            <span class="company">
              &nbsp;@&nbsp;
              <a
                href="https://www.linkedin.com/company/spyresync/"
                class="inline-link"
                rel="noopener noreferrer"
                target="_blank"
              >
                Spyresync
              </a>
            </span>
          </h3>
          <p class="range">March 2022 - June 2022</p>
          <div className="QgHxr">
            {/* <ul>
<li>Developed and styled interactive DAPPlications for Apple Music using Ember and SCSS</li>
<li>Built and shipped the Apple Music Extension for Facebook Messenger leveraging third-party and internal API integrations</li>
<li>Architected and implemented the user interface of Apple Music's embeddable web player widget for in-browser user authorization and full song playback</li>
<li>Contributed extensively to the creation of MusicKit JS, a public-facing JavaScript SDK for embedding Apple Music players into web applications</li>
</ul> */}
          </div>
        </>
      ),
    },
    {
      label: "Better Logics",
      content: (
        <>
          <h3>
            <span>Co-founder/ Senior Blockchain Developer</span>
            <span class="company">
              &nbsp;@&nbsp;
              <a
                href="https://www.linkedin.com/company/better-logics/mycompany/"
                class="inline-link"
                rel="noopener noreferrer"
                target="_blank"
              >
                Better Logics
              </a>
            </span>
          </h3>
          <p class="range">July 2022 &amp; Present</p>
          {/* <div className="QgHxr">
            <ul>
              <li>
                Deliver high-quality, robust production code for a diverse array
                of projects for clients including Harvard Business School,
                Everytown for Gun Safety, Pratt Institute, Koala Health,
                Vanderbilt University, The 19th News, and more
              </li>
              <li>
                Work alongside creative directors to lead the research,
                development, and architecture of technical solutions to fulfill
                business requirements
              </li>
              <li>
                Collaborate with designers, project managers, and other
                engineers to transform creative concepts into production
                realities for clients and stakeholders
              </li>
              <li>
                Provide leadership within engineering department through close
                collaboration, knowledge shares, and mentorship
              </li>
            </ul>
          </div> */}
        </>
      ),
    },
  ];


  const openWhatsApp = () => {
    const phoneNumber = '923136005881';
    
    const message = encodeURIComponent('Hello, let\'s chat on WhatsApp!');

    const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;

    window.open(whatsappURL, '_blank');
  };
  return (
    <div>
      <div className="layout__StyledContent-sc-1yw8zls-0 cZslOS">
        <div
          orientation="left"
          className="side__StyledSideElement-sc-1duznzb-0 hOvuuP"
        >
          <ul className="social__StyledSocialList-anu6nt-0 dVLQAC fade-enter-done">
            <li>
              <a
                href="https://github.com/UMARFAROOQAHMAD"
                aria-label="GitHub"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-github"
                >
                  <title>GitHub</title>
                  <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22" />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/umarfarooqmsc/"
                aria-label="Instagram"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-instagram"
                >
                  <title>Instagram</title>
                  <rect x={2} y={2} width={20} height={20} rx={5} ry={5} />
                  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
                  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/UmarFar52299665"
                aria-label="Twitter"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-twitter"
                >
                  <title>Twitter</title>
                  <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/umar-farooq-710068205/"
                aria-label="Linkedin"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-linkedin"
                >
                  <title>LinkedIn</title>
                  <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
                  <rect x={2} y={9} width={4} height={12} />
                  <circle cx={4} cy={4} r={2} />
                </svg>
              </a>
            </li>
          </ul>
        </div>
        <div
          orientation="right"
          className="side__StyledSideElement-sc-1duznzb-0 jSIwrL"
        >
          <div className="email__StyledLinkWrapper-sc-2epoq-0 jJFfEJ fade-enter-done">
            <a href="mailto:umarfarooqmsc@gmail.com"
                rel="noopener noreferrer">umarfarooqmsc@gmail.com</a>
          </div>
        </div>
        <div id="content">
          <main className="pages__StyledMainContainer-sc-1tzx2o8-0 ePyBYl fillHeight">
            <section className="hero__StyledHeroSection-sc-116xg8t-0 jnUoFC">
              <div
                className="fadeup-enter-done"
                style={{ transitionDelay: "100ms" }}
              >
                <h1>Hi, my name is</h1>
              </div>
              <div
                className="fadeup-enter-done"
                style={{ transitionDelay: "200ms" }}
              >
                <h2 className="big-heading">Umar Farooq.</h2>
              </div>
              <div
                className="fadeup-enter-done"
                style={{ transitionDelay: "300ms" }}
              >
                <h3 className="big-heading">I build things for the web3.</h3>
              </div>
              <div
                className="fadeup-enter-done"
                style={{ transitionDelay: "400ms" }}
              >
                <p>
                  I am a Software Engineer Working on Ethereum Virtual Machine
                  Currently, I’m focused on building accessible, human-centered
                  products .
                </p>

                <a
                className="email-link_first"
                target="_blank"
                onClick={openWhatsApp}
              >
                Say Hello
              </a>
              </div>
              {/* <div
                className="fadeup-enter-done"
                style={{ transitionDelay: "500ms" }}
              >
                <a
                  className="email-link"
                  href="https://www.newline.co/courses/build-a-spotify-connected-app"
                  target="_blank"
                  rel="noreferrer"
                >
                  Check out my course!
                </a>
              </div> */}
            </section>
            <section
              id="about"
              className="about__StyledAboutSection-sc-1ownso9-0 bNXWUU"
              data-sr-id={0}
              style={{
                visibility: "visible",
                opacity: 1,
                transform:
                  "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                transition:
                  "opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s",
              }}
            >
              <h2 className="numbered-heading">About Me</h2>
              <div className="inner">
                <div className="about__StyledText-sc-1ownso9-1 kNIdQM">
                  <div>
                    <p>
                      In the dynamic realm of blockchain technology, where
                      innovation and adaptability are paramount, one individual
                      stands out as both a seasoned senior blockchain developer
                      and the visionary CEO of a cutting-edge office. Meet
                      Umar Farooq, a trailblazer who seamlessly blends technical
                      expertise with leadership acumen, propelling his team and
                      organization into the forefront of the blockchain
                      landscape.
                      <a
                        href="https://www.linkedin.com/company/better-logics/mycompany/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Better Logics
                      </a>{" "}
                      for a variety of clients.
                    </p>
                    <p>
                      Here are a few technologies I’ve been working with
                      recently:
                    </p>
                  </div>
                  <ul className="skills-list">
                    <li>JavaScript (ES6+)</li>
                    <li>Solidty</li>
                    <li>React</li>
                    <li>MongoDB</li>
                    <li>Node.js</li>
                    <li>Express</li>
                  </ul>
                </div>
                <div className="about__StyledPic-sc-1ownso9-2 lbrXps">
                  <div className="wrapper">
                    <div
                      data-gatsby-image-wrapper=""
                      className="gatsby-image-wrapper gatsby-image-wrapper-constrained img"
                    >
                      <div style={{ maxWidth: 500, display: "block" }}>
                        <img
                          alt=""
                          role="presentation"
                          aria-hidden="true"
                          src="data:image/svg+xml;charset=utf-8,%3Csvg height='500' width='500' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E"
                          style={{
                            maxWidth: "100%",
                            display: "block",
                            position: "static",
                          }}
                        />
                      </div>
                      <div
                        aria-hidden="true"
                        data-placeholder-image=""
                        style={{
                          opacity: 0,
                          transition: "opacity 500ms linear 0s",
                          backgroundColor: "rgb(232, 232, 232)",
                          position: "absolute",
                          inset: 0,
                          objectFit: "cover",
                        }}
                      />
                      <picture>
                        <source
                          type="image/avif"
                          srcSet="https://avatars.githubusercontent.com/u/48089955?v=4
                    125w,
                    https://avatars.githubusercontent.com/u/48089955?v=4
                    250w,
                    https://avatars.githubusercontent.com/u/48089955?v=4
                    500w"
                          sizes="(min-width: 500px) 500px, 100vw"
                        />
                        <source
                          type="image/webp"
                          srcSet="/static/30a645f7db6038f83287d0c6042d3b2b/4155f/me.webp 125w,
/static/30a645f7db6038f83287d0c6042d3b2b/02deb/me.webp 250w,
/static/30a645f7db6038f83287d0c6042d3b2b/1ee78/me.webp 500w"
                          sizes="(min-width: 500px) 500px, 100vw"
                        />
                        <img
                          width={500}
                          height={500}
                          data-main-image=""
                          sizes="(min-width: 500px) 500px, 100vw"
                          decoding="async"
                          src="https://avatars.githubusercontent.com/u/48089955?v=4
                    "
                          srcSet="https://avatars.githubusercontent.com/u/48089955?v=4
                    125w,
                    https://avatars.githubusercontent.com/u/48089955?v=4
                    250w,
                    https://avatars.githubusercontent.com/u/48089955?v=4
                    500w"
                          alt="Headshot"
                          style={{ objectFit: "cover", opacity: 1 }}
                        />
                      </picture>
                      <noscript />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              id="jobs"
              className="jobs__StyledJobsSection-sc-59sdss-0 jdfosx"
              data-sr-id={2}
              style={{
                visibility: "visible",
                opacity: 1,
                transform:
                  "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                transition:
                  "opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s",
              }}
            >
              <h2 className="numbered-heading">Where I’ve Worked</h2>
              <div className="inner">
                <div>
                  <Tabs tabs={tabs} />
                </div>
                <div
                  role="tablist"
                  aria-label="Job tabs"
                  className="jobs__StyledTabList-sc-59sdss-1 sAHTI"
                >
                  {/* <button
              id="tab-0"
              role="tab"
              tabIndex={0}
              aria-selected="true"
              aria-controls="panel-0"
              className="jobs__StyledTabButton-sc-59sdss-2 dHdZWh"
            >
              <span>Upstatement</span>
            </button> */}
                  <div className="jobs__StyledHighlight-sc-59sdss-3 fLsUSJ" />
                </div>
                <div className="jobs__StyledTabPanels-sc-59sdss-4 jbGgbG">
                  <div
                    id=""
                    role="tabpanel"
                    tabIndex={0}
                    aria-labelledby="tab-0"
                    aria-hidden="false"
                    className="jobs__StyledTabPanel-sc-59sdss-5 QgHxr"
                  >
                    {/* <h3>
                <span>Lead Engineer</span>
                <span className="company">
                  &nbsp;@&nbsp;
                  <a
                    href="https://www.upstatement.com/"
                    className="inline-link"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Upstatement
                  </a>
                </span>
              </h3> */}
                    {/* <p className="range">May 2018 - Present</p> */}
                    {/* <div>
                <ul>
                  <li>
                    Deliver high-quality, robust production code for a diverse
                    array of projects for clients including Harvard Business
                    School, Everytown for Gun Safety, Pratt Institute, Koala
                    Health, Vanderbilt University, The 19th News, and more
                  </li>
                  <li>
                    Work alongside creative directors to lead the research,
                    development, and architecture of technical solutions to
                    fulfill business requirements
                  </li>
                  <li>
                    Collaborate with designers, project managers, and other
                    engineers to transform creative concepts into production
                    realities for clients and stakeholders
                  </li>
                  <li>
                    Provide leadership within engineering department through
                    close collaboration, knowledge shares, and mentorship
                  </li>
                </ul>
              </div> */}
                  </div>
                  {/* <div
              id="panel-1"
              role="tabpanel"
              tabIndex={-1}
              aria-labelledby="tab-1"
              aria-hidden="true"
              hidden=""
              className="jobs__StyledTabPanel-sc-59sdss-5 QgHxr"
            >
              <h3>
                <span>UI Engineer Co-op</span>
                <span className="company">
                  &nbsp;@&nbsp;
                  <a
                    href="https://www.apple.com/music/"
                    className="inline-link"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Apple
                  </a>
                </span>
              </h3>
              <p className="range">July - December 2017</p>
              <div>
                <ul>
                  <li>
                    Developed and styled interactive web applications for Apple
                    Music using Ember and SCSS
                  </li>
                  <li>
                    Built and shipped the Apple Music Extension for Facebook
                    Messenger leveraging third-party and internal API
                    integrations
                  </li>
                  <li>
                    Architected and implemented the user interface of Apple
                    Music's embeddable web player widget for in-browser user
                    authorization and full song playback
                  </li>
                  <li>
                    Contributed extensively to the creation of MusicKit JS, a
                    public-facing JavaScript SDK for embedding Apple Music
                    players into web applications
                  </li>
                </ul>
              </div>
            </div>
            <div
              id="panel-2"
              role="tabpanel"
              tabIndex={-1}
              aria-labelledby="tab-2"
              aria-hidden="true"
              hidden=""
              className="jobs__StyledTabPanel-sc-59sdss-5 QgHxr"
            >
              <h3>
                <span>Developer</span>
                <span className="company">
                  &nbsp;@&nbsp;
                  <a
                    href="https://web.northeastern.edu/scout/"
                    className="inline-link"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Scout Studio
                  </a>
                </span>
              </h3>
              <p className="range">Spring 2016 &amp; 2017</p>
              <div>
                <ul>
                  <li>
                    Collaborated with other student designers and engineers on
                    pro-bono projects to create new brands, design systems, and
                    websites for organizations in the community
                  </li>
                  <li>
                    Built and delivered technical solutions according to
                    stakeholder business requirements
                  </li>
                </ul>
              </div>
            </div>
            <div
              id="panel-3"
              role="tabpanel"
              tabIndex={-1}
              aria-labelledby="tab-3"
              aria-hidden="true"
              hidden=""
              className="jobs__StyledTabPanel-sc-59sdss-5 QgHxr"
            >
              <h3>
                <span>Software Engineer Co-op</span>
                <span className="company">
                  &nbsp;@&nbsp;
                  <a
                    href="https://starry.com/"
                    className="inline-link"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Starry
                  </a>
                </span>
              </h3>
              <p className="range">July - December 2016</p>
              <div>
                <ul>
                  <li>
                    Engineered and improved major features of Starry's
                    customer-facing Android web app using ES6, Handlebars,
                    Backbone, Marionette, and CSS
                  </li>
                  <li>
                    Proposed and implemented scalable solutions to issues
                    identified with cloud services and applications responsible
                    for communicating with the Starry Station internet router
                  </li>
                  <li>
                    Collaborated with designers and other developers to ensure
                    thoughtful and consistent user experiences across Starry’s
                    iOS and Android mobile apps
                  </li>
                </ul>
              </div>
            </div>
            <div
              id="panel-4"
              role="tabpanel"
              tabIndex={-1}
              aria-labelledby="tab-4"
              aria-hidden="true"
              hidden=""
              className="jobs__StyledTabPanel-sc-59sdss-5 QgHxr"
            >
              <h3>
                <span>Creative Technologist Co-op</span>
                <span className="company">
                  &nbsp;@&nbsp;
                  <a
                    href="https://us.mullenlowe.com/"
                    className="inline-link"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    MullenLowe
                  </a>
                </span>
              </h3>
              <p className="range">July - December 2015</p>
              <div>
                <ul>
                  <li>
                    Developed, maintained, and shipped production code for
                    client websites primarily using HTML, CSS, Sass, JavaScript,
                    and jQuery
                  </li>
                  <li>
                    Performed quality assurance tests on various sites to ensure
                    cross-browser compatibility and mobile responsiveness
                  </li>
                  <li>
                    Clients included JetBlue, Lovesac, U.S. Cellular, U.S.
                    Department of Defense, and more
                  </li>
                </ul>
              </div>
            </div> */}
                </div>
              </div>
            </section>
            <section id="projects">
              <h2
                className="numbered-heading"
                data-sr-id={3}
                style={{
                  visibility: "visible",
                  opacity: 1,
                  transform:
                    "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                  transition:
                    "opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s",
                }}
              >
                Some Things I’ve Built
              </h2>
              <ul className="featured__StyledProjectsGrid-ywnbqt-0 dnNJYD">
                <li
                  className="featured__StyledProject-ywnbqt-1 gvznfB"
                  data-sr-id={4}
                  style={{
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                    transition:
                      "opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s",
                  }}
                >
                  <div className="project-content">
                    <div>
                      <p className="project-overline">Featured Project</p>
                      <h3 className="project-title">
                        <a
                          href="https://halcyon-theme.netlify.com/"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          wireswap
                        </a>
                      </h3>
                      <div className="project-description">
                        <p>
                          A decentralized exchange and staking plan, that works on Binance Network.
                        </p>
                      </div>
                      <ul className="project-tech-list">
                        <li>React</li>
                        <li>Solidity</li>
                        <li>Typescript</li>
                        <li>Node.js</li>
                      </ul>
                      <div className="project-links">
                        <a
                          href="https://app.wireswap.io/#/"
                          aria-label="External Link"
                          className="external"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-external-link"
                          >
                            <title>External Link</title>
                            <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                            <polyline points="15 3 21 3 21 9" />
                            <line x1={10} y1={14} x2={21} y2={3} />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="project-image">
                    <a
                      href="https://app.wireswap.io/#/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <div
                        data-gatsby-image-wrapper=""
                        className="gatsby-image-wrapper gatsby-image-wrapper-constrained img"
                      >
                        <div style={{ maxWidth: 700, display: "block" }}>
                          <img
                            alt=""
                            role="presentation"
                            aria-hidden="true"
                            src="data:image/svg+xml;charset=utf-8,%3Csvg height='438' width='700' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E"
                            style={{
                              maxWidth: "100%",
                              display: "block",
                              position: "static",
                            }}
                          />
                        </div>
                        <img
                          aria-hidden="true"
                          data-placeholder-image=""
                          decoding="async"
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAACBklEQVQoz23O7W6bMBQGYO5iSoEY7GBjY2MwH/6AJk3SrStVlQbabJq23f9lTCRR1x+VHllH57zHtie76VOZmbidhDuf9vOMl7vxgyl3k7Bj2b/uH093D6dm89rfv3X7N2Gny/Rj3hP2+J+Zcf2iuvHv7x/jdHo5vv359fM4nrL2Ov2Y94R5eSfdUXZj4UbpRqaP3BxzN3Izk+4qn9+45j2uD1wfhDlwc5BuLLqp6Maz6XLLpX4vcnvk5zA3B4+3z0I/0XpgzROrh7Sa0Xq4dLL2Oa0er+qBzc2B1XOeNoPH6iHhOiblTSQT0SNuAFE+Uj4sfVjG1CTCkWIdEwNQuYjLm7j0oQqQiqjziHpIWJPr9ZI6IDa0vaftGkoXi36ZuiXRMe9w+ZVXD7zdFlUPM+OjClJDilsvVd8xazBvF7EE1LF6l5s9re6R3GK5BakNsUGpw/ldVN5JZRKpfVQBXENmPVJ+A6hEpFlEwk8aLG+pcii3ATbBqvGhilKbFDuidqxaA96F2IH5RyYkxlvJPYhFKjdLogOssVpz2ydNH3MN0yZMKjAvb0W9E/WGlBvIbudNrENqvVW+A7Egol/AMsQaFWskWsRNIjrITLCqQGri1BLek9zBrLmBlT9Ty6T1VmIbRDxaqS9hFiRNgFsf1T6q3oHUQNbHqQXMwsxA2kbUAKLDpP0H4Daf056mKVMAAAAASUVORK5CYII="
                          alt=""
                          style={{
                            opacity: 0,
                            transition: "opacity 500ms linear 0s",
                            objectFit: "cover",
                          }}
                        />
                        <picture>
                          <img
                            width={700}
                            height={438}
                            data-main-image=""
                            sizes="(min-width: 700px) 700px, 100vw"
                            decoding="async"
                            src={first}
                            // srcSet={first}
                            alt="Halcyon Theme"
                            style={{ objectFit: "cover", opacity: 1 }}
                          />
                        </picture>
                        <noscript />
                      </div>
                    </a>
                  </div>
                </li>
                <li
                  className="featured__StyledProject-ywnbqt-1 gvznfB"
                  data-sr-id={5}
                  style={{
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                    transition:
                      "opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s",
                  }}
                >
                  <div className="project-content">
                    <div>
                      <p className="project-overline">Featured Project</p>
                      <h3 className="project-title">
                        <a
                          href="https://spotify-profile.herokuapp.com/"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Splassive
                        </a>
                      </h3>
                      <div className="project-description">
                        <p>
                        Decentralized exchange and staking plan on Avalanche C-Chain, Working On EVM.
                        </p>
                      </div>
                      <ul className="project-tech-list">
                        <li>React</li>
                        <li>Solidity</li>
                        <li>MongoDB</li>
                        <li>Node.js</li>
                        <li>Express</li>
                      </ul>
                      <div className="project-links">
                        <a
                          href="https://splassive.com/"
                          aria-label="External Link"
                          className="external"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-external-link"
                          >
                            <title>External Link</title>
                            <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                            <polyline points="15 3 21 3 21 9" />
                            <line x1={10} y1={14} x2={21} y2={3} />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="project-image">
                    <a
                      href="https://splassive.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <div
                        data-gatsby-image-wrapper=""
                        className="gatsby-image-wrapper gatsby-image-wrapper-constrained img"
                      >
                        <div style={{ maxWidth: 700, display: "block" }}>
                          <img
                            alt=""
                            role="presentation"
                            aria-hidden="true"
                            src="data:image/svg+xml;charset=utf-8,%3Csvg height='438' width='700' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E"
                            style={{
                              maxWidth: "100%",
                              display: "block",
                              position: "static",
                            }}
                          />
                        </div>
                        <img
                          aria-hidden="true"
                          data-placeholder-image=""
                          decoding="async"
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAABcElEQVQoz32Q0U7bQBBF/RogjuOynpmdXW/W2E4cEwEhjgmymqpq+tg8tEWq2opf4hf6p5VjZJBjuE+r2b177h3HtQEKQMKOCOvxOb4nZ4gfMOhxngOUEX+NWCHAW+aTwSkRdZxANAHxb796+v35nkGQJOoze57fgxUi8Nxv5Wb/4xeTGo896MM7TAz4ckFEQoiiKL7sdutqu6k+fdxuq6oyxgRBt54zcr3OCABms9myWK2L1WKRXy+vy81dqMNjuOMemRGxhjABIisOCAQEcIQ9kEc95nqFAFEUZVkmpaSDeszucNSmPc7fDNtD56UzPHOllNNpMjuIiACAmefzubWWiOIkzrIsTdPGppTK8zwM6xU4g8GJ1ubqqkySxFrbfq+YASCJ401RXsQXYRi2jZRSzPzcmVlf5kuttZTydeyx79/eLB++/zTGWGujg5RSANCsoO5MRFpze9ESfH+8KtZ//j5OJqZJnqapMQYRG/J/k6JW26WIe1kAAAAASUVORK5CYII="
                          alt=""
                          style={{
                            opacity: 0,
                            transition: "opacity 500ms linear 0s",
                            objectFit: "cover",
                          }}
                        />
                        <picture>
                          <img
                            width={700}
                            height={438}
                            data-main-image=""
                            sizes="(min-width: 700px) 700px, 100vw"
                            decoding="async"
                            src={splassive}
                            alt="Spotify Profile"
                            style={{ objectFit: "cover", opacity: 1 }}
                          />
                        </picture>
                        <noscript />
                      </div>
                    </a>
                  </div>
                </li>
                <li
                  className="featured__StyledProject-ywnbqt-1 gvznfB"
                  data-sr-id={6}
                  style={{
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                    transition:
                      "opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s",
                  }}
                >
                  <div className="project-content">
                    <div>
                      <p className="project-overline">Featured Project</p>
                      <h3 className="project-title">
                        <a
                          href="https://www.newline.co/courses/build-a-spotify-connected-app"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Drip Network
                        </a>
                      </h3>
                      <div className="project-description">
                        <p>
                          A decentralized exchange and staking plan, that works on Binance Network.
                        </p>
                      </div>
                      <ul className="project-tech-list">
                        <li>React</li>
                        <li>Solidity</li>
                        <li>MongoDB</li>
                        <li>Node.js</li>
                        <li>Express</li>
                      </ul>
                      {/* <div className="project-links">
                        <a
                          href="https://www.newline.co/courses/build-a-spotify-connected-app"
                          aria-label="Course Link"
                          className="cta"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Learn More
                        </a>
                      </div> */}
                    </div>
                  </div>
                  <div className="project-image">
                    <a
                      href="https://www.newline.co/courses/build-a-spotify-connected-app"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <div
                        data-gatsby-image-wrapper=""
                        className="gatsby-image-wrapper gatsby-image-wrapper-constrained img"
                      >
                        <div style={{ maxWidth: 700, display: "block" }}>
                          <img
                            alt=""
                            role="presentation"
                            aria-hidden="true"
                            src="data:image/svg+xml;charset=utf-8,%3Csvg height='394' width='700' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E"
                            style={{
                              maxWidth: "100%",
                              display: "block",
                              position: "static",
                            }}
                          />
                        </div>
                        <img
                          aria-hidden="true"
                          data-placeholder-image=""
                          decoding="async"
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqklEQVQozwGfAmD9AKa44Jeq3Iqd1H+SznaIynKDx2+Ax2x9xWt8xWt8xmx+x2+ByXKDzXmK0HyO04WX142f2pKn3J2x3qW64QCgtdyps82VptSQotSImtB4jMlpfsJofcJle8FrgMRvhMZ1i8mBlc96ks2quuCQp9ePqdajut2Vs9ebu9cAmrHal6XDj6XSiqHTg5rOc4zHZH/AZX/AX3u9fZXJrLzcsMDfrsDelq/U5uz1r8bfmLjVvtXkjrnOjrzNAJWz3IGj1XaWy2yMxWSEwGGCvl5/u1l8ulp/ull/uV+Gu2ONvGGOumeWvY20zHSnwHSrv4S4xXy3wIPAwQCJq9FsX2JiXGFYYGtYXmhQV2FUW2dqi7hmkMJulsFnlb1ilblvob5pobpqpbh7tL9ztLpos7FvvLJ1w7MAg6jLQzk3Pzk3RURCRkRDNjUzQD8/nLfPlbnXlrrSjLfNea7ClMLMnMnOl8rJn9LNk87FYLymX8CkZ8emAHypzC0vMygsMyYrMCUnKC8vLFBQS2SRsWumyWmkwGanvGSqt2yyt3K7t2u7sGvArXHGrWjHplbFmFrKmQB4rM0pJSMzKygwKignIyAgGBI9ODN7qsGOw9iLws+Iw8uFxcaEycGW08d8zLaJ1LuZ3cKQ27tPypBQzo8AebrdWX2NX36PU3eNQm6FLlpuJ1RnO5KxPaK9O6KxOqaqOquiObCaNLORMrmKNr+GPMWGOseBP8yCStKIAH3J7G/F72C97FS35kuy4Eax20Kx1j2rxzqrvTqvtTmyqzi2ojm6mlHGn1nMnlDOlFHQkVnVk0/Ui0TUggB3zO9mw+pYveZNuOFCs9s6sNQ2r80zscgys78xtbUxuaoxvJ8ywJZJy5pS0ZlQ05JO1YxY2ZBU2otE138AtHoe7OPKgAAAAABJRU5ErkJggg=="
                          alt=""
                          style={{
                            opacity: 0,
                            transition: "opacity 500ms linear 0s",
                            objectFit: "cover",
                          }}
                        />
                        <picture>
                          <img
                            width={700}
                            height={394}
                            data-main-image=""
                            sizes="(min-width: 700px) 700px, 100vw"
                            decoding="async"
                            src={drip}
                            alt="Build a Spotify Connected App"
                            style={{ objectFit: "cover", opacity: 1 }}
                          />
                        </picture>
                        <noscript />
                      </div>
                    </a>
                  </div>
                </li>

                <li
                  className="featured__StyledProject-ywnbqt-1 gvznfB"
                  data-sr-id={6}
                  style={{
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                    transition:
                      "opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s",
                  }}
                >
                  <div className="project-content">
                    <div>
                      <p className="project-overline">Featured Project</p>
                      <h3 className="project-title">
                        <a
                          href="https://nftxpress.club/"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          NFT Express
                        </a>
                      </h3>
                      <div className="project-description">
                        <p>
                        It's NFT minting and NFT staking DAPP you can Breed , trade with NFT it's  working on Binance smart chain
                        </p>
                      </div>
                      <ul className="project-tech-list">
                        <li>React</li>
                        <li>Solidity</li>
                        <li>MongoDB</li>
                        <li>Node.js</li>
                        <li>Express</li>
                      </ul>
                      {/* <div className="project-links">
                        <a
                          href="https://www.newline.co/courses/build-a-spotify-connected-app"
                          aria-label="Course Link"
                          className="cta"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Learn More
                        </a>
                      </div> */}
                    </div>
                  </div>
                  <div className="project-image">
                    <a
                      href="https://www.newline.co/courses/build-a-spotify-connected-app"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <div
                        data-gatsby-image-wrapper=""
                        className="gatsby-image-wrapper gatsby-image-wrapper-constrained img"
                      >
                        <div style={{ maxWidth: 700, display: "block" }}>
                          <img
                            alt=""
                            role="presentation"
                            aria-hidden="true"
                            src="data:image/svg+xml;charset=utf-8,%3Csvg height='394' width='700' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E"
                            style={{
                              maxWidth: "100%",
                              display: "block",
                              position: "static",
                            }}
                          />
                        </div>
                        <img
                          aria-hidden="true"
                          data-placeholder-image=""
                          decoding="async"
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqklEQVQozwGfAmD9AKa44Jeq3Iqd1H+SznaIynKDx2+Ax2x9xWt8xWt8xmx+x2+ByXKDzXmK0HyO04WX142f2pKn3J2x3qW64QCgtdyps82VptSQotSImtB4jMlpfsJofcJle8FrgMRvhMZ1i8mBlc96ks2quuCQp9ePqdajut2Vs9ebu9cAmrHal6XDj6XSiqHTg5rOc4zHZH/AZX/AX3u9fZXJrLzcsMDfrsDelq/U5uz1r8bfmLjVvtXkjrnOjrzNAJWz3IGj1XaWy2yMxWSEwGGCvl5/u1l8ulp/ull/uV+Gu2ONvGGOumeWvY20zHSnwHSrv4S4xXy3wIPAwQCJq9FsX2JiXGFYYGtYXmhQV2FUW2dqi7hmkMJulsFnlb1ilblvob5pobpqpbh7tL9ztLpos7FvvLJ1w7MAg6jLQzk3Pzk3RURCRkRDNjUzQD8/nLfPlbnXlrrSjLfNea7ClMLMnMnOl8rJn9LNk87FYLymX8CkZ8emAHypzC0vMygsMyYrMCUnKC8vLFBQS2SRsWumyWmkwGanvGSqt2yyt3K7t2u7sGvArXHGrWjHplbFmFrKmQB4rM0pJSMzKygwKignIyAgGBI9ODN7qsGOw9iLws+Iw8uFxcaEycGW08d8zLaJ1LuZ3cKQ27tPypBQzo8AebrdWX2NX36PU3eNQm6FLlpuJ1RnO5KxPaK9O6KxOqaqOquiObCaNLORMrmKNr+GPMWGOseBP8yCStKIAH3J7G/F72C97FS35kuy4Eax20Kx1j2rxzqrvTqvtTmyqzi2ojm6mlHGn1nMnlDOlFHQkVnVk0/Ui0TUggB3zO9mw+pYveZNuOFCs9s6sNQ2r80zscgys78xtbUxuaoxvJ8ywJZJy5pS0ZlQ05JO1YxY2ZBU2otE138AtHoe7OPKgAAAAABJRU5ErkJggg=="
                          alt=""
                          style={{
                            opacity: 0,
                            transition: "opacity 500ms linear 0s",
                            objectFit: "cover",
                          }}
                        />
                        <picture>
                          <img
                            width={700}
                            height={394}
                            data-main-image=""
                            sizes="(min-width: 700px) 700px, 100vw"
                            decoding="async"
                            src={nft}
                            alt="Build a Spotify Connected App"
                            style={{ objectFit: "cover", opacity: 1 }}
                          />
                        </picture>
                        <noscript />
                      </div>
                    </a>
                  </div>
                </li>
              </ul>
            </section>
            <section className="projects__StyledProjectsSection-sc-1v1fime-0 iavbTH">
              <h2
                data-sr-id={7}
                style={{
                  visibility: "visible",
                  opacity: 1,
                  transform:
                    "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                  transition:
                    "opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s",
                }}
              >
                Other Noteworthy Projects
              </h2>
              <a
                className="inline-link archive-link"
                href="/archive"
                data-sr-id={8}
                style={{
                  visibility: "visible",
                  opacity: 1,
                  transform:
                    "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                  transition:
                    "all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s",
                }}
              >
                view the archive
              </a>
              <ul className="projects-grid">
                <li
                  className="projects__StyledProject-sc-1v1fime-1 gJHaRi"
                  style={{
                    transition:
                      "all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s",
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                  }}
                  data-sr-id={9}
                >
                  <div className="project-inner">
                    <header>
                      <div className="project-top">
                        <div className="folder">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={1}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-folder"
                          >
                            <title>Folder</title>
                            <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" />
                          </svg>
                        </div>
                        <div className="project-links">
                          <a
                            href="https://quest.guildqb.com/"
                            aria-label="External Link"
                            className="external"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              role="img"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-external-link"
                            >
                              <title>External Link</title>
                              <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                              <polyline points="15 3 21 3 21 9" />
                              <line x1={10} y1={14} x2={21} y2={3} />
                            </svg>
                          </a>
                        </div>
                      </div>
                      <h3 className="project-title">
                        <a
                          href="https://quest.guildqb.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Quest GuildQB
                        </a>
                      </h3>
                      <div className="project-description">
                        <p>
                        Get Exclusive Reward GuildQB reward on this DAPP its working on Avalanche C-Chain.
                        </p>
                      </div>
                    </header>
                    <footer>
                      <ul className="project-tech-list">
                        <li>React</li>
                        <li>Solidity</li>
                      </ul>
                    </footer>
                  </div>
                </li>
                <li
                  className="projects__StyledProject-sc-1v1fime-1 gJHaRi"
                  style={{
                    transition:
                      "all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s",
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                  }}
                  data-sr-id={10}
                >
                  <div className="project-inner">
                    <header>
                      <div className="project-top">
                        <div className="folder">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={1}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-folder"
                          >
                            <title>Folder</title>
                            <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" />
                          </svg>
                        </div>
                        <div className="project-links">
                          {/* <a
                            href="https://github.com/bchiang7/time-to-have-more-fun"
                            aria-label="GitHub Link"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              role="img"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-github"
                            >
                              <title>GitHub</title>
                              <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22" />
                            </svg>
                          </a> */}
                          <a
                            href="https://bookofgatesofficial.com/"
                            aria-label="External Link"
                            className="external"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              role="img"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-external-link"
                            >
                              <title>External Link</title>
                              <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                              <polyline points="15 3 21 3 21 9" />
                              <line x1={10} y1={14} x2={21} y2={3} />
                            </svg>
                          </a>
                        </div>
                      </div>
                      <h3 className="project-title">
                        <a
                          href="https://bookofgatesofficial.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Book of Gates
                        </a>
                      </h3>
                      <div className="project-description">
                        <p>
                          An NFT minting DAPP that works on Binance
                          blockchain, and road map of upcoming project.
                        </p>
                      </div>
                    </header>
                    <footer>
                      <ul className="project-tech-list">
                        <li>React</li>
                        <li>Solidity</li>
                        <li>Node.js</li>
                      </ul>
                    </footer>
                  </div>
                </li>
                <li
                  className="projects__StyledProject-sc-1v1fime-1 gJHaRi"
                  style={{
                    transition:
                      "all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s",
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                  }}
                  data-sr-id={11}
                >
                  <div className="project-inner">
                    <header>
                      <div className="project-top">
                        <div className="folder">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={1}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-folder"
                          >
                            <title>Folder</title>
                            <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" />
                          </svg>
                        </div>
                        <div className="project-links">
                          <a
                            href="https://sainftmarketkplace.xyz/"
                            aria-label="External Link"
                            className="external"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              role="img"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-external-link"
                            >
                              <title>External Link</title>
                              <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                              <polyline points="15 3 21 3 21 9" />
                              <line x1={10} y1={14} x2={21} y2={3} />
                            </svg>
                          </a>
                        </div>
                      </div>
                      <h3 className="project-title">
                        <a
                          href="https://sainftmarketkplace.xyz/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          SaiNFT Marketplace
                        </a>
                      </h3>
                      <div className="project-description">
                        <p>
                        NFT Marketplace on binance smart chain with NFT Minting And Staking DAPP.
                        </p>
                      </div>
                    </header>
                    <footer>
                      <ul className="project-tech-list">
                        <li>Node</li>
                        <li>Express</li>
                        <li>React</li>
                        <li>Solidity</li>
                      </ul>
                    </footer>
                  </div>
                </li>
                <li
                  className="projects__StyledProject-sc-1v1fime-1 gJHaRi"
                  style={{
                    transition:
                      "all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s",
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                  }}
                  data-sr-id={12}
                >
                  <div className="project-inner">
                    <header>
                      <div className="project-top">
                        <div className="folder">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={1}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-folder"
                          >
                            <title>Folder</title>
                            <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" />
                          </svg>
                        </div>
                        <div className="project-links">
                          <a
                            href="https://compassionate-pare-c6563e.netlify.app/"
                            aria-label="External Link"
                            className="external"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              role="img"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-external-link"
                            >
                              <title>External Link</title>
                              <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                              <polyline points="15 3 21 3 21 9" />
                              <line x1={10} y1={14} x2={21} y2={3} />
                            </svg>
                          </a>
                        </div>
                      </div>
                      <h3 className="project-title">
                        <a
                          href="https://compassionate-pare-c6563e.netlify.app/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Tronium | Investment platform
                        </a>
                      </h3>
                      <div className="project-description">
                        <p>
                          A staking investment plan that works on Tron
                          Blockchain Network.
                        </p>
                      </div>
                    </header>
                    <footer>
                      <ul className="project-tech-list">
                        <li>React</li>
                        <li>Solidity</li>
                        <li>Node.js</li>
                      </ul>
                    </footer>
                  </div>
                </li>
                <li
                  className="projects__StyledProject-sc-1v1fime-1 gJHaRi"
                  style={{
                    transition:
                      "all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s",
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                  }}
                  data-sr-id={13}
                >
                  <div className="project-inner">
                    <header>
                      <div className="project-top">
                        <div className="folder">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={1}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-folder"
                          >
                            <title>Folder</title>
                            <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" />
                          </svg>
                        </div>
                        <div className="project-links">
                          {/* <a
                            href="https://github.com/bchiang7/google-keep-vue-firebase"
                            aria-label="GitHub Link"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              role="img"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-github"
                            >
                              <title>GitHub</title>
                              <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22" />
                            </svg>
                          </a> */}
                          <a
                            href="https://magenta-moonbeam-01d84b.netlify.app/"
                            aria-label="External Link"
                            className="external"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              role="img"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-external-link"
                            >
                              <title>External Link</title>
                              <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                              <polyline points="15 3 21 3 21 9" />
                              <line x1={10} y1={14} x2={21} y2={3} />
                            </svg>
                          </a>
                        </div>
                      </div>
                      <h3 className="project-title">
                        <a
                          href="https://magenta-moonbeam-01d84b.netlify.app/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          CSTSwap 6 Patti
                        </a>
                      </h3>
                      <div className="project-description">
                        <p>
                          That's a six card game that, works on Binance
                          Blockchain Network.
                        </p>
                      </div>
                    </header>
                    <footer>
                      <ul className="project-tech-list">
                        <li>React</li>
                        <li>Solidity</li>
                        <li>Node.js</li>
                      </ul>
                    </footer>
                  </div>
                </li>
                <li
                  className="projects__StyledProject-sc-1v1fime-1 gJHaRi"
                  style={{
                    transition:
                      "all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s",
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                  }}
                  data-sr-id={14}
                >
                  <div className="project-inner">
                    <header>
                      <div className="project-top">
                        <div className="folder">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={1}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-folder"
                          >
                            <title>Folder</title>
                            <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" />
                          </svg>
                        </div>
                        <div className="project-links">
                          <a
                            href="https://zealous-albattani-f42826.netlify.app/"
                            aria-label="External Link"
                            className="external"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              role="img"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-external-link"
                            >
                              <title>External Link</title>
                              <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                              <polyline points="15 3 21 3 21 9" />
                              <line x1={10} y1={14} x2={21} y2={3} />
                            </svg>
                          </a>
                        </div>
                      </div>
                      <h3 className="project-title">
                        <a
                          href="https://zealous-albattani-f42826.netlify.app/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Titano.finance
                        </a>
                      </h3>
                      <div className="project-description">
                        <p>
                          A staking and swaping plan, that's work on Binance
                          Blockchain Network.
                        </p>
                      </div>
                    </header>
                    <footer>
                      <ul className="project-tech-list">
                        <li>React</li>
                        <li>Solidity</li>
                        <li>Node.js</li>
                      </ul>
                    </footer>
                  </div>
                </li>

                <li
                  className="projects__StyledProject-sc-1v1fime-1 gJHaRi"
                  style={{
                    transition:
                      "all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s",
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                  }}
                  data-sr-id={14}
                >
                  <div className="project-inner">
                    <header>
                      <div className="project-top">
                        <div className="folder">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={1}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-folder"
                          >
                            <title>Folder</title>
                            <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" />
                          </svg>
                        </div>
                        <div className="project-links">
                          <a
                            href="https://alightzone.world/"
                            aria-label="External Link"
                            className="external"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              role="img"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-external-link"
                            >
                              <title>External Link</title>
                              <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                              <polyline points="15 3 21 3 21 9" />
                              <line x1={10} y1={14} x2={21} y2={3} />
                            </svg>
                          </a>
                        </div>
                      </div>
                      <h3 className="project-title">
                        <a
                          href="https://alightzone.world/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Alightzone
                        </a>
                      </h3>
                      <div className="project-description">
                        <p>
                          A MLM plan with binary matrix of DECENTRALIZED CRYPTO
                          WALLET, that's work on Binance Blockchain Network.
                        </p>
                      </div>
                    </header>
                    <footer>
                      <ul className="project-tech-list">
                        <li>HTML</li>
                        <li>Solidity</li>
                        <li>.Net</li>
                      </ul>
                    </footer>
                  </div>
                </li>

                <li
                  className="projects__StyledProject-sc-1v1fime-1 gJHaRi"
                  style={{
                    transition:
                      "all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s",
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                  }}
                  data-sr-id={14}
                >
                  <div className="project-inner">
                    <header>
                      <div className="project-top">
                        <div className="folder">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={1}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-folder"
                          >
                            <title>Folder</title>
                            <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" />
                          </svg>
                        </div>
                        <div className="project-links">
                          <a
                            href="https://earnpassive.club/"
                            aria-label="External Link"
                            className="external"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              role="img"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-external-link"
                            >
                              <title>External Link</title>
                              <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                              <polyline points="15 3 21 3 21 9" />
                              <line x1={10} y1={14} x2={21} y2={3} />
                            </svg>
                          </a>
                        </div>
                      </div>
                      <h3 className="project-title">
                        <a
                          href="https://earnpassive.club/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Earn Passive Club
                        </a>
                      </h3>
                      <div className="project-description">
                        <p>
                          A MLM plan with binary matrix plan of DECENTRALIZED
                          CRYPTO WALLET, that's work on Binance Blockchain
                          Network.
                        </p>
                      </div>
                    </header>
                    <footer>
                      <ul className="project-tech-list">
                        <li>HTML</li>
                        <li>Solidity</li>
                        <li>.Net</li>
                      </ul>
                    </footer>
                  </div>
                </li>

                <li
                  className="projects__StyledProject-sc-1v1fime-1 gJHaRi"
                  style={{
                    transition:
                      "all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s",
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                  }}
                  data-sr-id={14}
                >
                  <div className="project-inner">
                    <header>
                      <div className="project-top">
                        <div className="folder">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={1}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-folder"
                          >
                            <title>Folder</title>
                            <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" />
                          </svg>
                        </div>
                        <div className="project-links">
                          <a
                            href="https://www.maticwin.com/"
                            aria-label="External Link"
                            className="external"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              role="img"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-external-link"
                            >
                              <title>External Link</title>
                              <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                              <polyline points="15 3 21 3 21 9" />
                              <line x1={10} y1={14} x2={21} y2={3} />
                            </svg>
                          </a>
                        </div>
                      </div>
                      <h3 className="project-title">
                        <a
                          href="https://www.maticwin.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MATICWIN Smart Finance
                        </a>
                      </h3>
                      <div className="project-description">
                        <p>
                          A MLM plan with binary matrix plan of DECENTRALIZED
                          DAPP, that's work on Polygon Blockchain Network.
                        </p>
                      </div>
                    </header>
                    <footer>
                      <ul className="project-tech-list">
                        <li>HTML</li>
                        <li>Solidity</li>
                        <li>.Net</li>
                      </ul>
                    </footer>
                  </div>
                </li>
              </ul>
              {/* <button className="more-button">Show More</button> */}
            </section>
            <section
              id="contact"
              className="contact__StyledContactSection-sc-1m8df4t-0 fMdyDu"
              data-sr-id={15}
              style={{
                visibility: "visible",
                opacity: 1,
                transform:
                  "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                transition:
                  "opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s",
              }}
            >
              <h2 className="numbered-heading overline">What’s Next?</h2>
              <h2 className="title">Get In Touch</h2>
              <p>
                Although I’m not currently looking for any new opportunities, my
                inbox is always open. Whether you have a question or just want
                to say hi, I’ll try my best to get back to you!
              </p>
              <a
                className="email-link"
                target="_blank"
                onClick={openWhatsApp}
              >
                Say Hello
              </a>
            </section>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Hero;
