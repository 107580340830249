import React from 'react';
import "./Header.css";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from "../../Assets/logo.png"

const Header = () => {
  return (
    <div>
      <header className="nav__StyledHeader-ghklvd-0 hLTFFw nav_desktop">
    <nav className="nav__StyledNav-ghklvd-1 kFCHyX">
      <div className="logo fade-enter-done" tabIndex={-1}>
        <a href="/" aria-label="home">
          <div className="hex-container">
            <svg
              id="hex"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              viewBox="0 0 84 96"
            >
              <title>Hexagon</title>
              <g transform="translate(-8.000000, -2.000000)">
                <g transform="translate(11.000000, 5.000000)">
                  <polygon
                    stroke="currentColor"
                    strokeWidth={5}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    points="39 0 0 22 0 67 39 90 78 68 78 23"
                    fill="currentColor"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div className="logo-container">
            <svg
              id="logo"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              viewBox="0 0 84 96"
            >
              <title>Logo</title>
              <g transform="translate(-8.000000, -2.000000)">
                <g transform="translate(11.000000, 5.000000)">
                  <polygon
                    id="Shape"
                    stroke="currentColor"
                    strokeWidth={5}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    points="39 0 0 22 0 67 39 90 78 68 78 23"
                  />
                  <path
                    d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
                    fill="currentColor"
                  />
                </g>
              </g>
            </svg>
          </div>
        </a>
      </div>
      <div className="nav__StyledLinks-ghklvd-2 ihALZY">
        <ol>
          <li
            className="fadedown-enter-done"
            style={{ transitionDelay: "0ms" }}
          >
            <a href="/#about">About</a>
          </li>
          <li
            className="fadedown-enter-done"
            style={{ transitionDelay: "100ms" }}
          >
            <a href="/#jobs">Experience</a>
          </li>
          <li
            className="fadedown-enter-done"
            style={{ transitionDelay: "200ms" }}
          >
            <a href="/#projects">Work</a>
          </li>
          <li
            className="fadedown-enter-done"
            style={{ transitionDelay: "300ms" }}
          >
            <a href="/#contact">Contact</a>
          </li>
        </ol>
        <div
          className="fadedown-enter-done"
          style={{ transitionDelay: "400ms" }}
        >
          <a
            className="resume-button"
            href="/Umar Farooq's Resume.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Resume
          </a>
        </div>
      </div>
    </nav>
  </header>

  <Navbar collapseOnSelect expand="lg" className="nav_mob fixed-top">
      <Container>
        <Navbar.Brand href="#home" className='text-white'>Umar Farooq</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto ms-auto nav_cneter">
            <Nav.Link href="#about" className='nav_link_after text-white'>About</Nav.Link>
            <Nav.Link href="#jobs" className='nav_link_after2  text-white'>Experience</Nav.Link>
            <Nav.Link href="#projects" className='nav_link_after3  text-white'>Work</Nav.Link>
            <Nav.Link href="#contact" className='nav_link_after4  text-white'>Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  )
}

export default Header
