import logo from './logo.svg';
import './App.css';
import Hero from './Components/Hero/Hero';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import loadder from "./Assets/git_loader.gif";
import { useEffect, useState } from 'react';


function App() {

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(true);
    }, 2000);
  }, []);

  return (
    <div className="">
      <Header />
      <Hero />
      <Footer />
    </div>
  );
}

export default App;
